import React from "react";
import { useStyletron } from "baseui";
import { Grid, Cell } from "baseui/layout-grid";
import { DisplayMedium } from "baseui/typography";

export default function Error404() {
  const [css, theme] = useStyletron();

  return (
    <>
      <div
        className={css({
          paddingTop: theme.sizing.scale2400,
          paddingBottom: theme.sizing.scale2400,
        })}
      >
        <Grid>
          <Cell span={12}>
            <div
              className={css({
                textAlign: "center",
                marginTop: "200px",
              })}
            >
              <DisplayMedium $style={{ fontWeight: 300 }}>
                Page not found
              </DisplayMedium>
            </div>
          </Cell>
        </Grid>
      </div>
    </>
  );
}
